<template>
  <div class="page container">
    <div class="page-title mt-5">
      <h1>メールアドレス登録</h1>
      <p>主たる営業担当者様のメールアドレスと法人で分かる方は法人番号も入力してください。</p>
    </div>
    <b-form @submit="onSubmit">
      <div class="card biz-primary mt-3">
        <div class="card-body">
          <div class="biz-form-group">
            <label class="biz-form-label required">メールアドレス</label>
            <div class="biz-form-body">
              <div class="row">
                <div class="col-5">
                  <b-form-group id="input-group-1" class="biz-input-group">
                    <b-form-input id="input-1" v-model="form.email" type="email" required placeholder="例) tanaka@watabe.co.jp">
                    </b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div class="biz-form-group">
            <label class="biz-form-label optional">法人番号</label>
            <div class="biz-form-body">
              <div class="row">
                <div class="col-5">
                  <b-form-group id="input-group-1" class="biz-input-group" description="※重複チェックと次画面以降で社名入力の手間を削減するために使用します">
                    <b-form-input id="input-1" v-model="form.corporateNumber" type="text" placeholder="例) XXXXXXX">
                    </b-form-input>
                  </b-form-group>
                </div>
                <div class="col biz-offset">
                  <a href="javascript:void(0)" class="biz-link-button" v-on:click="onOpenSearchDialog">
                    <i class="icon">
                      <font-awesome-icon class="va-m" icon="search"></font-awesome-icon>
                    </i>
                    法人番号検索
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div id="recaptcha-container">
            <div id="recaptcha"></div>
          </div>
        </div>
      </div>
      <div class="mt-5 text-center">
        <p>ご入力いただいたメールアドレスに登録案内メールをお送りいたします。</p>
        <j-button buttonType="submit" variant="primary" class="mt-2" size="lg">
          メールを送信
        </j-button>
        <br />
        <j-button variant="light" class="mt-4" to="/initiate/policy">
          <font-awesome-icon class="va-m" icon="arrow-left"></font-awesome-icon>
          前へ戻る
        </j-button>
        <p class="text-muted mt-4 small">このサイトはreCAPTCHAとGoogleによって保護されているため、<br/>
          <a href="https://policies.google.com/privacy" target="_blank">プライバシーポリシー</a>と
          <a href="https://policies.google.com/terms" target="_blank">利用規約</a>が対象になります。</p>
      </div>
    </b-form>
  </div>
</template>

<style scoped lang="scss">

</style>
<script>
  import Init from '@/model/Init.js'
  
  export default {    
    data: () => ({
      form: {},
      sitekey:  process.env.VUE_APP_RECAPTCHA_SITE_KEY
    }),
    created(){
    },
    methods: {
      async onSubmit(event){
        event.preventDefault();
        await this.$recaptchaLoaded();
        this.form.token = await this.$recaptcha('register');
        Init.save(this.form).then(() => {
          this.$router.push({
            path: "/initiate/mail-address-complete",
            query: {
              email: this.$data.form.email
            }
          });
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      },
      onOpenSearchDialog(){

      },
    }
  }
</script>